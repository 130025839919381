import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useKeycloak } from 'react-keycloak';
import { navigate } from 'gatsby';

import { useQuery } from '@apollo/react-hooks';
import { Add, Close, Edit, Info, List, ViewModule } from '@material-ui/icons';
import { Box, Chip, Container } from '@material-ui/core';

import { isEmpty } from '../../utils/ObjectUtils';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { CardWrapperUI } from '../Common/styled/CardWrapperUI';
import Loading from '../Common/Loading';
import { Span } from '../Common/styled/Text';
import { GET_ADVANCED_TOOLS } from '../../queries/AdvancedTools/AdvancedTools';
import { ArticleContainerUI } from '../Common/styled/ArticleContainerUI';
import { SectionBar } from '../../componentsUI/SectionBar';
import { PageContentUI } from '../Common/styled/PageContentUI';
import TableCollapse from '../../componentsUI/tableCollapse';
import FlexGridTable from '../../componentsUI/FlexGridTable';
import { AdvancedToolCardImageItem } from './card/AdvancedToolCardImageItem';
import { AdvancedToolCardImageOverlay } from './card/AdvancedToolCardImageOverlay';
import { AdvancedToolCardImageFooter } from './card/AdvancedToolCardImageFooter';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';
import { InnerHTML } from '../Common/InnerHTML';
import useStyles from './styled/StudyAdvancedToolsMakeStyles';

export const AdvancedToolsView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const provider = useSelector((state) => state.provider);
  const [keycloak] = useKeycloak();
  const [selected, setSelected] = useState([]);
  const [viewAsList, setViewAsList] = useState(false);
  const isAdvancedToolAdmin = keycloak.tokenParsed.roles && keycloak.tokenParsed.roles.includes('https://api.cloud.alma-medical.com/advanced.tools.admin');

  if (!provider) {
    return t('you.are.not.allowed.to.perform.this.action');
  }

  const { loading, data } = useQuery(GET_ADVANCED_TOOLS, { variables: { providerUuid: provider.uuid } });

  const advancedTools = data && data.advancedTools && data.advancedTools.edges;
  const displayingTools = advancedTools && advancedTools.length;

  const selectedAdvancedTool = (advancedTools && selected.length > 0)
    && advancedTools[selected[0]] && advancedTools[selected[0]].node;

  const getAdvancedToolName = (item) => (
    <Span className={item.node.favorite ? classes.favorite : classes.tool}>{item.node.name}</Span>
  );

  const getAdvancedToolData = (item) => {
    const isSelected = selectedAdvancedTool && selectedAdvancedTool.uuid === item.node.uuid;
    const chipsClass = isSelected ? classes.chips : `${classes.chips} tool-selected`;
    return (
      <Box className={`${classes.toolSubData} ${isSelected && 'tool-selected'}`}>
        <Box className="provider-name">{item.node.provider.name}</Box>
        {item.node.ceCertification && (
          <Chip size="small" className={chipsClass} label="CE" />)}
        {item.node.fdaCertification && (
          <Chip size="small" className={chipsClass} label="FDA" />)}
        {item.node.research && (
          <Chip size="small" className={chipsClass} label={t('research').toUpperCase()} />)}
        {item.node.category && (
          <Chip size="small" className={chipsClass} label={item.node.category} />)}
        {item.node.speciality && (
          <Chip size="small" className={chipsClass} label={t(item.node.speciality.toLowerCase()).toUpperCase()} />)}
        {item.node.bodyRegion && (
          <Chip size="small" className={chipsClass} label={t(item.node.bodyRegion.toLowerCase()).toUpperCase()} />)}
      </Box>
    );
  };

  const AdvancedToolCollapse = ({ item }) => (
    <Box className={classes.collapsed}>
      <InnerHTML content={!!item.node.info5 && item.node.info5} />
    </Box>
  );

  const goBack = () => navigate('/dashboard');

  const handleOpen = (evt) => {
    evt.stopPropagation();
    navigate(`/advanced-tools/detail/${selectedAdvancedTool.uuid}`);
  };

  const handleGoOpen = (evt, index) => {
    evt.stopPropagation();
    const advancedTool = advancedTools[index].node;

    navigate(`/advanced-tools/detail/${advancedTool.uuid}`);
  };

  const handleEdit = (evt) => {
    evt.stopPropagation();
    navigate(`/advanced-tools/edit/${selectedAdvancedTool.uuid}`);
  };

  const handleAdd = (evt) => {
    evt.stopPropagation();
    navigate('/advanced-tools/new');
  };

  const toggleListView = () => {
    setViewAsList(!viewAsList);
  };

  const getListIcon = () => (viewAsList ? <ViewModule /> : <List />);
  const listLabel = viewAsList ? 'view.cards' : 'view.as.list';

  const buttons = [
    { name: 'add.tool', icon: Add, handleClick: handleAdd, disabled: !isAdvancedToolAdmin },
    { name: 'edit.tool', icon: Edit, handleClick: handleEdit, disabled: !selectedAdvancedTool },
    { name: 'tool.info', icon: Info, handleClick: handleOpen, disabled: !selectedAdvancedTool },
    { name: 'divider2', type: 'divider' },
    { name: listLabel, icon: getListIcon, handleClick: toggleListView, disabled: false },
    { name: 'go.back', icon: Close, handleClick: goBack },
  ];

  const infoButton = {
    Icon: Info,
    tooltip: 'tool.info',
  };

  return (
    <ArticleContainerUI>
      <Navbar>
        <SectionBar
          title="alma.advanced.tools"
          subtitle={`${t('displaying.tools')} (${displayingTools})`}
          items={buttons}
        />
      </Navbar>

      <Container maxWidth="xl" className="article">
        <PageContentUI>
          {(loading && isEmpty(data))
            ? <Loading />
            : (
              <CardWrapperUI>
                {!!advancedTools && (
                  viewAsList ? (
                    <TableCollapse
                      items={advancedTools}
                      rowActionButton={infoButton}
                      GetTitle={getAdvancedToolName}
                      GetSubtitle={getAdvancedToolData}
                      GetCollapse={AdvancedToolCollapse}
                      selected={selected}
                      setSelected={setSelected}
                      handleGoto={handleGoOpen}
                      isMore={advancedTools
                      && advancedTools.pageInfo
                      && advancedTools.pageInfo.hasNextPage}
                      responsive={false}
                    />
                  ) : (
                    <FlexGridTable
                      items={advancedTools}
                      cardActionButton={infoButton}
                      ImageItem={AdvancedToolCardImageItem}
                      ImageOverlay={AdvancedToolCardImageOverlay}
                      ImageFooter={AdvancedToolCardImageFooter}
                      selected={selected}
                      setSelected={setSelected}
                      handleGoto={handleGoOpen}
                      isMore={advancedTools
                      && advancedTools.pageInfo
                      && advancedTools.pageInfo.hasNextPage}
                    />
                  )
                )}
                {!advancedTools && (
                  <AlertWrapperUI>
                    <AlertUI severity="warning" title={t('info')}>
                      {t('no.advanced.tools')}
                    </AlertUI>
                  </AlertWrapperUI>
                )}
              </CardWrapperUI>
            )}
        </PageContentUI>
      </Container>
    </ArticleContainerUI>
  );
};
